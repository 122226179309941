<template>
  <div class="main">
    <el-form ref="form" label-position="left" label-width="180px">
      <div class="top">
        <h2>学生基础信息:</h2>
        <el-form-item v-for="(i,n) in objArr" v-if="type===4?true:n!==4" :key="i.key" :label="i.label">
          {{ n === 0 ? `${formData[i.key]}(${formData.student_no})` : formData[i.key] }}
        </el-form-item>
      </div>
      <div class="top">
        <h2>{{ txtObj[type].txt }}:</h2>
        <el-form-item v-for="i in objArr_" v-if="i.key === 'sign_status' ? formData.is_sign !== '否' : true" :key="i.key" :label="i.label">
          {{ formData[i.key] }}
          <el-button v-if="i.key==='sign_status'&&formData['sign_url']" size="mini" style="margin-left: 12px" type="primary" @click="onOpen">查看协议</el-button>
        </el-form-item>
      </div>
      <div v-if="type===2||(type===4&&Number(formData.status)===2 )" class="top">
        <h2>学生复学信息:</h2>
        <el-form-item label="复学时间">
          {{ formData.end_time }}
        </el-form-item>
        <el-form-item label="复学后行政班">
          {{ formData.suspend_schooling_class }}
        </el-form-item>
        <el-form-item label="备注">
          {{ formData.situation ? formData.situation : formData.suspend_schooling_notes }}
        </el-form-item>
      </div>
      <div class="top">
        <h2>操作记录:</h2>
        <div style="width: 50%">
          <el-row :gutter="20">
            <el-col v-for="(i,n) in objArr__" :key="i.key" :span="12">
              <el-form-item v-if="((type===4&&Number(formData.status)===2)||type===2)?n<4:n<2" :label="i.label">
                {{ formData[i.key] }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="top">
        <el-button v-if="Number(formData.status)===1" type="primary" @click="dialogVisible=true">退学后复学</el-button>
        <el-button @click="$router.back()">返回</el-button>
      </div>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" title="学生复学信息" width="30%">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" label-width="130px">
        <el-form-item label="复学时间：" prop="end_time">
          <el-date-picker v-model="ruleForm.end_time" format="yyyy-MM-dd HH:mm" placeholder="请选择复学时间" type="datetime" value-format="yyyy-MM-dd HH:mm">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="复学后行政班：" prop="suspend_schooling_class_id">
          <el-select v-model="ruleForm.suspend_schooling_class_id" placeholder="请选择复学后行政班">
            <el-option v-for="item in options" :key="item.class_id" :label="item.class_name" :value="item.class_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生情况：" prop="situation">
          <el-input v-model="ruleForm.situation" :maxlength="300" :rows="2" placeholder="请输入学生情况" show-word-limit type="textarea"/>
        </el-form-item>
      </el-form>
      <div style="margin-top: 48px;display: flex;justify-content: right">
        <el-button type="primary" @click="onOk">确认</el-button>
        <el-button @click="dialogVisible=false">点错了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  _config: {"route": {"path": "look", "meta": {"title": "查看", style: 2}}},
  data() {
    return {
      objArr: [
        {label: '学生姓名', key: 'student_name'},
        {label: '校区', key: 'school_name'},
        {label: '年级', key: 'grade_name'},
        {label: '行政班', key: 'class_name'},
        {label: '学生状态', key: 'status_name'},
      ],
      objArr_: [],
      objArr__: [],
      options: [
        {value: '1', label: '行政班1'},
        {value: '2', label: '行政班2'},
        {value: '3', label: '行政班3'},
        {value: '4', label: '行政班4'},
        {value: '5', label: '行政班5'},
        {value: '6', label: '行政班6'},
        {value: '7', label: '行政班7'},
      ],
      type: 0,
      txtObj: {
        1: {txt: '学生留级信息', url: 'api/student/student-adjust/stay-down-details',},
        2: {txt: '学生休学信息', url: 'api/student/student-adjust/suspend-schooling-details',},
        3: {txt: '学生转学信息', url: 'api/student/student-adjust/transfer-details',},
        4: {txt: '学生退学信息', url: 'api/student/student-adjust/drop-out-details',},
      },
      formData: {},
      dialogVisible: false,
      ruleForm: {},
      rules: {
        end_time: [
          {required: true, message: '请选择复学时间', trigger: 'blur'}
        ],
        suspend_schooling_class_id: [
          {required: true, message: '请选择复学后行政班', trigger: 'blur'}
        ],
        situation: [
          {required: true, message: '请输入学生情况', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    onOpen() {
      window.open(this.formData['sign_url'])
    },
    onOk() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.ruleForm.id = this.$route.query.id
          this.$_axios2.post('api/student/student-adjust/drop-out-restore', this.ruleForm, {logic: 1}).then(() => {
            this.$message.success('操作成功')
            this.$router.back()
            // this.dialogVisible = false
            // this.$_axios2.get(this.txtObj[this.type].url, {params: {id: this.$route.query.id}}).then(res => {
            //   this.formData = res.data.data
            // })
          })
        }
      });
    }
  },
  created() {
    this.type = Number(this.$route.query.type)
    this.$_axios2.get(this.txtObj[this.type].url, {params: {id: this.$route.query.id}}).then(res => {
      this.formData = res.data.data
      this.$_axios2.get('api/student/student-adjust/resume-schooling-class', {params: {student_id: res.data.data.student_id}}).then(res => {
        this.options = res.data.data
      })
    })


    switch (Number(this.type)) {
      case 1:
        this.objArr_ = [
          {label: '留级后转入年级', key: 'stay_down_grade'},
          {label: '留级后转入行政班', key: 'stay_down_class'},
          {label: '是否需签署协议', key: 'is_sign'},
          {label: '签署状态', key: 'sign_status'},
          {label: '留级原因', key: 'stay_down_reason'},
        ]
        this.objArr__ = [
          {label: '操作人', key: 'creator'},
          {label: '操作时间', key: 'created_at'}
        ]
        break
      case 2:
        this.objArr_ = [
          {label: '开始休学时间', key: 'start_time'},
          {label: '预计复学时间', key: 'estimate_end_time'},
          {label: '休学原因', key: 'suspend_schooling_reason'}
        ]
        this.objArr__ = [
          {label: '休学操作人', key: 'creator'},
          {label: '操作时间', key: 'created_at'},
          {label: '复学操作人', key: 'su_creator'},
          {label: '复学操作时间', key: 'su_created_at'}
        ]
        break
      case 3:
        this.objArr_ = [
          {label: '转学校区', key: 'transfer_school_name'},
          {label: '转学时间', key: 'transfer_time'},
          {label: '转学原因', key: 'transfer_reason'},
        ]
        this.objArr__ = [
          {label: '操作人', key: 'creator'},
          {label: '操作时间', key: 'created_at'}
        ]
        break
      case 4:
        this.objArr_ = [
          {label: '退学时间', key: 'drop_out_time'},
          {label: '退学原因', key: 'drop_out_reason'},
          {label: '备注', key: 'drop_out_remarks'}
        ]
        this.objArr__ = [
          {label: '退学操作人', key: 'creator'},
          {label: '退学操作时间', key: 'created_at'},
          {label: '复学操作人', key: 'su_creator'},
          {label: '复学操作时间', key: 'su_created_at'}
        ]
        break
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  padding-right: 12px;
  box-sizing: border-box;
}

h2 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.top {
  min-height: 18px;
  background: #FFF;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
}
</style>